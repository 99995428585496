export const triggerCharactersIncludingWhitespace = char => $position => {
  const regexp = new RegExp(`(?:^)?${char}[^${char}]*`, 'g');

  const textFrom = $position.before();
  const textTo = $position.end();

  const text = $position.doc.textBetween(textFrom, textTo, '\0', '\0');

  let match;

  // eslint-disable-next-line
  while ((match = regexp.exec(text))) {
    const prefix = match.input.slice(Math.max(0, match.index - 1), match.index);

    if (!/^[\s\0]?$/.test(prefix)) {
      // eslint-disable-next-line
      continue;
    }

    const from = match.index + $position.start();
    let to = from + match[0].length;

    if (from < $position.pos && to >= $position.pos) {
      return { range: { from, to }, text: match[0] };
    }
  }
  return null;
};
