<template>
  <div
    class="message-text__wrap"
    :class="{
      'show--quoted': isQuotedContentPresent,
      'hide--quoted': !isQuotedContentPresent,
    }"
  >
    <div v-if="!isEmail" v-dompurify-html="message" class="text-content" />
    <letter v-else class="text-content" :html="message" />
    <div v-if="showTranslate" class="message-text--source-language">
      source language {{ sourceLanguage }}
    </div>
    <letter v-if="showTranslate" class="text-content" :html="translate" />
    <button
      v-if="showQuoteToggle"
      class="text-slate-300 dark:text-slate-300 cursor-pointer text-xs py-1"
      @click="toggleQuotedContent"
    >
      <span v-if="showQuotedContent" class="flex items-center gap-0.5">
        <fluent-icon icon="chevron-up" size="16" />
        {{ $t('CHAT_LIST.HIDE_QUOTED_TEXT') }}
      </span>
      <span v-else class="flex items-center gap-0.5">
        <fluent-icon icon="chevron-down" size="16" />
        {{ $t('CHAT_LIST.SHOW_QUOTED_TEXT') }}
      </span>
    </button>
  </div>
</template>

<script>
import Letter from 'vue-letter';

export default {
  components: { Letter },
  props: {
    message: {
      type: String,
      default: '',
    },
    isEmail: {
      type: Boolean,
      default: true,
    },
    displayQuotedButton: {
      type: Boolean,
      default: false,
    },
    translate: {
      type: String,
      default: '',
    },
    sourceLanguage: {
      type: String,
      default: '',
    },
    isCanMessageTranslate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showQuotedContent: false,
    };
  },
  computed: {
    isQuotedContentPresent() {
      if (!this.isEmail) {
        return this.message.includes('<blockquote');
      }
      return this.showQuotedContent;
    },
    showQuoteToggle() {
      if (!this.isEmail) {
        return false;
      }
      return this.displayQuotedButton;
    },
    showTranslate() {
      return (
        this.isCanMessageTranslate &&
        Boolean(this.sourceLanguage) &&
        this.sourceLanguage.toLowerCase() !== 'en'
      );
    },
  },
  methods: {
    toggleQuotedContent() {
      this.showQuotedContent = !this.showQuotedContent;
    },
  },
};
</script>
<style lang="scss">
.text-content {
  overflow: auto;

  ul,
  ol {
    padding-left: var(--space-two);
  }
  table {
    margin: 0;
    border: 0;

    td {
      margin: 0;
      border: 0;
    }

    tr {
      border-bottom: 0 !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: var(--font-size-normal);
  }
}

.show--quoted {
  blockquote {
    @apply block;
  }
}

.hide--quoted {
  blockquote {
    @apply hidden;
  }
}

.message-text--source-language {
  color: var(--v-50);
  padding: 3px 6px;
  background-color: var(--v-200);
  font-size: var(--font-size-micro);
  line-height: 1.8;
  width: fit-content;
  margin: 10px var(--space-small) 10px auto;
  text-align: right;
  display: flex;
  border-radius: 1rem 1rem 0.4rem 1rem;
  text-transform: lowercase;
}
</style>
