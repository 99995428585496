<template>
  <woot-modal :show.sync="isOpened" :on-close="closeModal" size="medium">
    <div class="column">
      <woot-modal-header header-title="Create forum topic from chat" />
      <div class="row">
        <form class="column" @submit.prevent="submitForm">
          <label :class="{ error: $v.formValues.name.$error }">
            Topic name
            <input
              v-model="formValues.name"
              type="text"
              @input="$v.formValues.name.$touch"
            />
            <span v-if="$v.formValues.name.$error" class="message">
              Topic name cannot be shorter than 20 symbols
            </span>
          </label>
          <label :class="{ error: $v.formValues.description.$error }">
            Topic description
            <textarea
              v-model="formValues.description"
              rows="3"
              type="text"
              @input="$v.formValues.description.$touch"
            />
            <span v-if="$v.formValues.description.$error" class="message">
              Topic description cannot be shorter than 30 symbols
            </span>
          </label>
          <label>
            Сategory
            <select v-model="formValues.category">
              <option
                v-for="option in categories"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </label>
          <label>
            Tag
            <select v-model="formValues.tag">
              <option v-for="option in tags" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </label>
          <table v-if="formValues.messages.length" class="messages">
            <thead>
              <tr>
                <th>№</th>
                <th>Author</th>
                <th colspan="2">Message</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(message, index) in formValues.messages"
                :key="message.id"
              >
                <td>
                  {{ index }}
                </td>
                <td>
                  <select v-model="message.senderType">
                    <option value="user">User</option>
                    <option value="support">Support</option>
                  </select>
                </td>
                <td>
                  <textarea v-model="message.text" rows="2" type="text" />
                </td>
                <td>
                  <woot-button
                    icon="dismiss"
                    variant="clear"
                    color-scheme="secondary"
                    @click="deleteMessage(message.id)"
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4">
                  <div class="w-full flex-container align-right">
                    <woot-button
                      icon="add"
                      size="small"
                      type="button"
                      variant="smooth"
                      color-scheme="success"
                      @click="addEmptyMessage"
                    >
                      Add new message
                    </woot-button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
          <div class="w-full flex-container align-right">
            <woot-button type="submit" :is-loading="isPending">
              Send form
            </woot-button>
          </div>
        </form>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import types from 'dashboard/store/mutation-types';

export default {
  data() {
    return {
      formValues: {
        name: '',
        description: '',
        tag: null,
        category: null,
        messages: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      tags: 'getForumTags',
      isOpened: 'getForumModalStatus',
      categories: 'getForumCategories',
      chatMessages: 'getForumMessages',
      isPending: 'getForumModalRequestStatus',
    }),
  },
  validations: {
    formValues: {
      name: {
        required,
        minLength: minLength(20),
      },
      description: {
        required,
        minLength: minLength(30),
      },
    },
  },
  watch: {
    tags() {
      this.setInitialTag();
    },
    categories() {
      this.setInitialCategory();
    },
    chatMessages() {
      this.setInitialMessages();
    },
    isOpened(nextValue) {
      if (nextValue === false) {
        this.$v.$reset();
        this.resetValues();
      }
    },
  },
  mounted() {
    this.setInitialTag();
    this.setInitialMessages();
    this.setInitialCategory();
  },
  methods: {
    resetValues() {
      this.formValues.name = '';
      this.formValues.description = '';

      this.setInitialTag();
      this.setInitialMessages();
      this.setInitialCategory();
    },
    setInitialTag() {
      this.formValues.tag = this.tags[0] || null;
    },
    setInitialCategory() {
      const productsCategory = this.categories.find(
        v => v === 'AEJuice Products'
      );
      this.formValues.category = productsCategory || this.categories[0] || null;
    },
    setInitialMessages() {
      this.formValues.messages = this.chatMessages || [];
    },
    closeModal() {
      this.$store.commit(types.CLOSE_FORUM_MODAL);
    },
    addEmptyMessage() {
      this.formValues.messages = [
        ...this.formValues.messages,
        {
          text: '',
          senderType: 'support',
          id: `empty-${Math.floor(Math.random() * 1e10)}`,
        },
      ];
    },
    deleteMessage(id) {
      this.formValues.messages = this.formValues.messages.filter(
        message => message.id !== id
      );
    },
    submitForm() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.$store.dispatch('sendForumForm', this.formValues);
    },
  },
};
</script>

<style scoped lang="scss">
.messages {
  width: 100%;
  margin: 0 0 2.6rem;
  text-align: center;
  border-collapse: collapse;

  & td {
    border: 1px solid var(--s-50);
  }

  & tr {
    vertical-align: top;
  }

  & tbody td:last-child {
    width: 40px;
  }

  & textarea,
  & select {
    margin-bottom: 0 !important;
  }

  & .actions {
    text-align: right;
  }
}
</style>
