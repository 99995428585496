import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import { marked } from 'marked';

import { MESSAGE_TYPE } from '../../../shared/constants/messages';
import MessagesApi from '../../api/inbox/message';
import AccountApi from '../../api/account';
import types from '../mutation-types';

export const state = {
  isLoading: false,
};

export const getters = {
  getIsLoading(_state) {
    return _state.isLoading;
  },
};

export const actions = {
  createTicket: async ({ commit, dispatch }, currentChat) => {
    commit(types.SET_TICKET_LOADING_STATUS, true);
    try {
      const inboxId = 20;
      const contactId = currentChat.meta.sender.id; // ИД контакта, для которого создана беседа, он становится sender
      const assigneeId = currentChat.meta.assignee?.id; // ИД сапорта на которого будет назначена беседа
      const initiatedAt =
        currentChat.additional_attributes.initiated_at.timestamp;

      const messages = [];
      let lastMessageId;

      const messagedRes = await MessagesApi.get(currentChat.id);
      const chatMessages = messagedRes.data.payload;

      messages.push(...chatMessages);
      lastMessageId = messages[0].id;

      while (lastMessageId) {
        // eslint-disable-next-line no-await-in-loop
        const prevMessagesRes = await MessagesApi.getPreviousMessages({
          before: lastMessageId,
          conversationId: currentChat.id,
        });

        const prevChatMessages = prevMessagesRes.data.payload;
        const isMoreMessagesMayExist = prevChatMessages.length === 20;

        messages.unshift(...prevChatMessages);

        lastMessageId = isMoreMessagesMayExist ? prevChatMessages[0].id : null;
      }

      const header = `Chat started: ${initiatedAt.replace(/(.\()+.*/, '')}`;
      const body = messages.reduce(
        (
          bodyText,
          { content, sender, message_type, created_at, attachments = [] }
        ) => {
          if (
            message_type !== MESSAGE_TYPE.INCOMING &&
            message_type !== MESSAGE_TYPE.OUTGOING
          ) {
            return bodyText;
          }

          const messageTextContent = content ?? '';
          const isWithAttachemnts = attachments.length > 0;
          const messageTime = format(fromUnixTime(created_at), 'hh:mm:ss a');

          let messageText = `(${messageTime}) ${sender?.name}: ${messageTextContent}\n`;

          if (isWithAttachemnts) {
            const attachmentsText = attachments.reduce(
              (attachText, attachmen) => {
                return attachText.concat(
                  `  ${attachmen.file_type}: ${attachmen.data_url}\n`
                );
              },
              'Attachments:\n'
            );

            messageText = messageText.concat(attachmentsText);
          }

          return bodyText.concat(messageText);
        },
        ''
      );

      const div = document.createElement('div');
      div.innerHTML = marked.parse(`${header}\n\n${body}`, {
        renderer: (() => {
          const defaultRenderer = new marked.Renderer();

          defaultRenderer.link = href => href;
          return defaultRenderer;
        })(),
      });

      const payload = {
        status: 'open',
        inbox_id: inboxId,
        contact_id: contactId,
        assignee_id: assigneeId,
        message: {
          content: div.textContent,
        },
      };

      await AccountApi.createConversation(payload);

      await dispatch(
        'toggleStatus',
        {
          status: 'resolved',
          conversationId: currentChat.id,
        },
        { root: true }
      );
    } finally {
      commit(types.SET_TICKET_LOADING_STATUS, false);
    }
  },
};

export const mutations = {
  [types.SET_TICKET_LOADING_STATUS](_state, isLoading) {
    _state.isLoading = isLoading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
